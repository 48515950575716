import { ComponentProps } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Box from '@base/Box'

type Props = {
  BoxProps?: ComponentProps<typeof Box>
}

const Loading = ({ BoxProps }: Props) => (
  <Box textAlign='center' data-testid='loading' {...BoxProps}>
    <CircularProgress color='primary' size={50} thickness={5} />
  </Box>
)

export default Loading
